.footer_grugs {
  background-image: linear-gradient(
      to right,
      #0b0b0b 2%,
      transparent,
      #0b0b0b 98%
    ),
    url("../../../assets/images/footer-grugs.png");
  background-size: contain;
}
