@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Avara";
  src: url("assets/fonts/Avara-Black.otf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Avara";
  src: url("assets/fonts/Avara-Bold.otf");
  font-weight: 700;
  font-style: normal;
}

html {
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

body {
  min-width: 375px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0b0b0b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.game_title_hover span {
    transition: opacity 300ms ease-in-out;
}

.game_title_hover img {
  transition: opacity 300ms ease-in-out;
  opacity: 0;
}

.game_title_hover:hover span {
  opacity: 0;
}

.game_title_hover:hover img {
  opacity: 1;
}