.horizontal_roadmap_border {
  margin-left: -14px;
  height: 100%;
  background-image: linear-gradient(to right, white 50%, #ffffff00 0%);
  background-position: bottom;
  background-size: 28px 100%;
  background-repeat: repeat-x;
}

.vertical_roadmap_border {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, white 50%, #ffffff00 0%);
  background-position: right;
  background-size: 100% 28px;
  background-repeat: repeat-y;
}

.text_vertical {
  writing-mode: tb;
  transform: rotate(180deg);
}
