.introduction_main {
  position: relative;
  overflow: hidden;

  // background: url("../../../assets/images/introduction-gradient-min.png");
  // background-size: contain;
  // background-position: right bottom;
  // background-repeat: no-repeat;

  &::before {
    content: "";
    position: absolute;
    top: 10vh;
    right: 70%;
    width: 100%;
    height: 100%;
    background: url("../../../assets/images/introduction-gradient-min.png");
    background-size: contain;
    background-position: left bottom;
    background-repeat: no-repeat;
    transform: scaleX(-1);
    z-index: -1;
  }
  &::after {
    content: "";
    position: absolute;
    top: 10vh;
    left: 30%;
    width: 100%;
    height: 100%;
    background: url("../../../assets/images/introduction-gradient-min.png");
    background-size: contain;
    background-position: left bottom;
    background-repeat: no-repeat;
    z-index: -1;
  }
}

.introduction_title_background {
  transition: transform 1s ease-in-out;
  transform: scaleX(0);
  &:global(.visible) {
    transform: scaleX(1);
  }
}

@media screen and (min-width: 473px) {
  .forceLineBreak {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .introduction_main {
    &::before,
    &::after {
      left: 0;
      background-size: cover;
      background-position: center 40vh;
    }
    &::after {
      display: none;
    }
  }
}
