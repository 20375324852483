.blob_arena {
  display: none;
  position: absolute !important;
  top: 0;
  background: linear-gradient(45deg, #2bbffb, #0073ec);
}

.cloud {
  top: 5%;
  height: 30vh;
  max-height: 300px;
  animation: cloud 25s linear infinite;
  &:global(.large-cloud) {
    top: 30%;
    width: 60vw;
    height: unset;
    max-height: unset;
    animation-duration: 180s;
    animation-delay: -75s;
  }
}

@keyframes cloud {
  0% {
    left: 100%;
  }
  100% {
    left: calc(-30vh * 2.2);
  }
}

.floating_blob {
  animation: float 2s ease-in-out infinite;
  &:global(.right-blob) {
    animation-delay: -0.5s;
    .blobShadow {
      left: 57%;
      animation-delay: -0.5s;
    }
  }
}

.blobShadow {
  position: absolute;
  left: 45%;
  width: 50%;
  animation: float-shadow 2s ease-in-out infinite;
  background: radial-gradient(50% 50%, #000000cc 0%, transparent 100%);
  aspect-ratio: 2;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes float-shadow {
  0% {
    transform: translateX(-50%) translateY(0px);
  }
  50% {
    transform: translateX(-50%) translateY(15px);
  }
  100% {
    transform: translateX(-50%) translateY(0px);
  }
}

.blob_arena_amma_video {
  width: 100vw;
  height: 100vh;
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  z-index: 999;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}