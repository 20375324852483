.rising_revenant {
  display: none;
  background: url("../../../../assets/images/rising-revenant/rising-revenant.jpg");
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.rising_revenant_video {
  position: absolute;
  video {
    object-fit: cover;
    object-position: 53%;
  }
}
