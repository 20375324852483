.grugSmashedImageCanSmash {
  cursor: url("../../assets/images/grug-smashed/stone-axe-idle.png") 28 28,
    pointer;
  &:active {
    cursor: url("../../assets/images/grug-smashed/stone-axe-hit.png") 56 28,
      pointer;
  }
}

.grugSmashedImageShake {
  animation: shake 0.5s ease-in-out infinite;
}

.grugSmashedImage {
  user-select: none;
  animation: float 8s ease-in-out infinite;
}

.grugSmashedFloatingEntry {
  animation: float 8s ease-in-out infinite;
  user-select: none;
  cursor: pointer;
}

.shiningStar {
  transition: opacity 2s linear;
  :global {
    svg {
      position: absolute;
    }
    .group {
      transform: translate(50%, 50%);
    }
    path {
      transform: translate(-42.5px, -42.5px);
    }
    .large {
      &:local {
        animation: large 3s forwards;
      }
    }
    .large-2 {
      &:local {
        animation: large-2 3s;
      }
    }
    .small {
      &:local {
        animation: small 3s;
      }
    }
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-15px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes shake {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  5% {
    transform: translate(-5px, 3px) rotate(-2deg);
  }
  10% {
    transform: translate(6px, -4px) rotate(2deg);
  }
  15% {
    transform: translate(-7px, 5px) rotate(-3deg);
  }
  20% {
    transform: translate(8px, -6px) rotate(2deg);
  }
  25% {
    transform: translate(-6px, 7px) rotate(-2deg);
  }
  30% {
    transform: translate(7px, -5px) rotate(3deg);
  }
  35% {
    transform: translate(-5px, 6px) rotate(-1deg);
  }
  40% {
    transform: translate(6px, -7px) rotate(2deg);
  }
  45% {
    transform: translate(-4px, 8px) rotate(-2deg);
  }
  50% {
    transform: translate(5px, -6px) rotate(1deg);
  }
  55% {
    transform: translate(-6px, 5px) rotate(-3deg);
  }
  60% {
    transform: translate(7px, -4px) rotate(2deg);
  }
  65% {
    transform: translate(-5px, 7px) rotate(-1deg);
  }
  70% {
    transform: translate(6px, -6px) rotate(3deg);
  }
  75% {
    transform: translate(-4px, 8px) rotate(-2deg);
  }
  80% {
    transform: translate(7px, -5px) rotate(1deg);
  }
  85% {
    transform: translate(-6px, 6px) rotate(-3deg);
  }
  90% {
    transform: translate(5px, -4px) rotate(2deg);
  }
  95% {
    transform: translate(-4px, 5px) rotate(-1deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes large {
  0% {
    opacity: 0;
    transform: rotate(0deg) scale(0);
  }
  50% {
    opacity: 1;
    transform: rotate(360deg) scale(1.5);
  }
  100% {
    opacity: 1;
    transform: rotate(360deg) scale(150);
  }
}

@media screen and (max-width: 500px) {
  @keyframes large {
    0% {
      opacity: 0;
      transform: rotate(0deg) scale(0);
    }
    50% {
      opacity: 1;
      transform: rotate(360deg) scale(1.5);
    }
    100% {
      opacity: 1;
      transform: rotate(360deg) scale(70);
    }
  }
}

@keyframes large-2 {
  0% {
    opacity: 0;
    transform: rotate(45deg) scale(0);
  }
  50% {
    opacity: 0.8;
    transform: rotate(405deg) scale(1.1);
  }
}

@keyframes small {
  0% {
    opacity: 0;
    transform: rotate(0deg) scale(0);
  }
  50% {
    opacity: 0.5;
    transform: rotate(-360deg) scale(1.5);
  }
}
